<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
       <v-flex xs3 text-left>
              <span class="mainHeader">VIEW ADMINS</span>
            </v-flex>
      <v-flex xs12 py-6 text-left>
        <v-card
          tile
        class="pa-4"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
       <v-layout wrap>
                <v-flex xs12 v-if="adminList">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left subhed"><b>S.No.</b></th>
                          <th class="text-left subhed"><b>User Name</b></th>
                          <th class="text-left subhed"><b>Phone No</b></th>
                          <th class="text-left subhed"><b>Email address</b></th>
                          <th class="text-left subhed"><b>Jewellery Name</b></th>
                          <th class="text-left subhed"><b>Action</b></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(value, i) in adminList" :key="i" class="table">
                          <!-- <td>{{ i + 1 }}</td> -->
                          <td>
                            <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span>
                          </td>
                          <td>{{ value.username }}</td>
                          <td v-if="value.phone">{{ value.phone }}</td>

                          <td>{{ value.email }}</td>
                          <td>
                           {{ value.jewelleryName }}
                          </td>
                          <td>
                             <v-icon
                              size="18" class="pr-2"
                              style="cursor: pointer;"
                              @click="(curItem = value), (editdialogue = true)"
                              >mdi-circle-edit-outline</v-icon
                            >
                            <v-icon
                              size="18"
                              style="cursor: pointer;"
                              @click="(deletedialog = true), (curId = value._id)"
                              >mdi-delete-outline</v-icon
                            >
                          </td>
 </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
                </v-layout>
                 <v-layout wrap>
        <v-flex xs12 pt-4>
          <v-pagination
            small
            color="#3F053C"
            v-model="page"
            :length="Pagelength"
          >
          </v-pagination>
        </v-flex>
      </v-layout>
        </v-card>
         <v-dialog width="400px" v-model="deletedialog">
        <v-card width="400px">
          <v-toolbar dark color="#3F053C" dense flat class="body-2">
            <v-toolbar-title> Confirm </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pa-4"
            >Are you sure you want to delete this admin?
          </v-card-text>
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="deletedialog = false"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              class="body-2 font-weight-bold"
              outlined
              @click="deleteCategory()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
           <v-form ref="form2" v-model="valid2">
        <v-dialog width="400px" v-model="editdialogue">
          <v-card width="400px">
            <v-toolbar dark color="#3F053C" dense flat class="body-2">
              <v-toolbar-title> Edit Admin </v-toolbar-title>
            </v-toolbar>
            <!-- {{ curItem}} -->
        <v-layout wrap>
          <v-flex pa-4 xs12>
            <v-layout wrap>
              <v-flex xs12 pb-2 text-left><span class="subhed">Email Address</span></v-flex>
               <v-flex xs12 pb-3>
             <v-text-field
            label="Email"
            color="#b234a9"
            outlined
            v-model="curItem.email"
            type="email"
            hide-details
            dense
          ></v-text-field>
           </v-flex>
            <v-flex xs12 pb-2 text-left><span class="subhed">Jewellery Name</span></v-flex>
               <v-flex xs12 pb-3>
             <v-text-field
             color="#b234a9"
            label="Jewellery Name"
            outlined
            v-model="curItem.jewelleryName"
            type="text"
            hide-details
            dense
          ></v-text-field>
           </v-flex>
           <v-flex xs12 pb-2 text-left><span class="subhed">Phone Number</span></v-flex>
               <v-flex xs12 align-self-end>
             <v-text-field
             color="#b234a9"
            label="Phone"
            outlined
            v-model="curItem.phone"
            type="number"
            hide-details
            dense
          ></v-text-field>
           </v-flex>
              
         
                <v-flex xs12 pb-2 text-left><span class="subhed">Username</span></v-flex>
               <v-flex xs12>
             <v-text-field
             color="#b234a9"
            label="username"
            outlined
            v-model="curItem.username"
            type="text"
            hide-details
            dense
          ></v-text-field>
           </v-flex>
             <v-flex xs12 text-left><span class="subhed">Address</span></v-flex>
               <v-flex xs12>
             <v-textarea
            label="Address"
            outlined
            v-model="curItem.location"
            type="Address"
            hide-details
            dense
          ></v-textarea>
           </v-flex>
           <v-flex xs2 pt-2>
            <v-btn dark color="#3F053C" @click="validateInput()">
              Save
            </v-btn>
           </v-flex>
            </v-layout>
          </v-flex>
          </v-layout>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="editdialogue = false"
                >Cancel</v-btn
              >
              <v-btn
                color="#3F053C"
                class="body-2 font-weight-bold"
                outlined
                @click="validateInput()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
       page: 1,
      Pagelength: 0,
      username:null,
      name:null,
      address:null,
      phone:null,
      email:null,
      deletedialog:false,
      curId:null,
       editdialogue:false,
      curItem:[],
      valid2:false,
      adminList:[],
    };
  },
    mounted() {
    this.getData();
  },
  watch:{
    page(){
      this.getData();
    }
  },
  methods: {
     getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/user/all/jewelleryadmins",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          keyword: this.keyword,
          page: this.page,
          count: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.adminList = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
      validateInput() {
      if (!this.curItem.email) {
        this.msg = "Please provide email";
        this.showsnackbar = true;
        return;
      } else if (!this.curItem.jewelleryName) {
        this.msg = "Please provide name";
        this.showsnackbar = true;
        return;
      } else if (!this.curItem.phone) {
        this.msg = "Please provide phone";
        this.showsnackbar = true;
        return;
      } else if (!this.curItem.username) {
        this.msg = "Please provide username";
        this.showsnackbar = true;
        return;
      }  else if (!this.curItem.location) {
        this.msg = "Please provide address";
        this.showsnackbar = true;
        return;
      }  else {
        this.editCategory();
      }
    },
      editCategory() {
      axios({
        url: "/user/adminedit",
        method: "POST",
        headers: {
         "x-auth-token": localStorage.getItem("token"),
        },
        data: {
            id:this.curItem._id,
          username:this.curItem.username,
          email:this.curItem.email,
          jewelleryName: this.curItem.jewelleryName,
          phone: this.curItem.phone,
          location: this.curItem.location,
        },
      })
        .then((response) => {
          this.editdialogue = false;
          this.appLoading = false;
          if (response.data.status==true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
   deleteCategory() {
      axios({
        url: "/asset/remove",
        method: "POST",
        headers: {
            "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.deletedialog = false;
          this.appLoading = false;
          if (response.data.status==true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
